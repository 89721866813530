<template>
  <!-- 票据管理详情 -->
  <div class="page-info-content">
    <h3 class="page-sub-title">
      基础信息
    </h3>
    <DetailsPage
      class="interval"
      details-title=""
      :detail-obj="detailsInfoObj.detailObj"
      :details-item-arr="detailsInfoObj.detailItemArr"
      :column="2"
      border
    />
    <h3 class="page-sub-title">
      渠道业务人员信息
    </h3>
    <Table
      :item-data="detailsInfoObj.itemData"
      :list-data="detailsInfoObj.detailObj.billChannelPersonnelList"
    />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import Table from '@/components/Table.vue'
import { queryBillChannelDesc } from '@/api/piaoju.js'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      detailsInfoObj: {
        detailObj: {
          billChannelPersonnelList: []
        },
        detailItemArr: [
          { label: '渠道名称', value: 'channelName' },
          { label: '渠道联系人', value: 'channelContacts' },
          { label: '渠道联系方式', value: 'phoneNo' },
          { label: '联系地址', value: 'address' },
          { label: '渠道创建人', value: 'createUser' }
        ],
        itemData: [
          { label: '人员名称', prop: 'personnelName', width: 200 },
          { label: '人员联系方式', prop: 'personnelPhone', width: 160 },
          { label: '创建时间', prop: 'createTime', width: 160 }
        ]
      }
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      if (this.$route.query.id) {
        queryBillChannelDesc(this.$route.query.id, res => {
          this.detailsInfoObj.detailObj.billChannelPersonnelList = res.data.billChannelPersonnelInformationList
          this.detailsInfoObj.detailObj.channelName = res.data.billChannelInformation.channelName
          this.detailsInfoObj.detailObj.channelContacts = res.data.billChannelInformation.channelContacts
          this.detailsInfoObj.detailObj.phoneNo = res.data.billChannelInformation.phoneNo
          this.detailsInfoObj.detailObj.address = res.data.billChannelInformation.address
          this.detailsInfoObj.detailObj.createUser = res.data.billChannelInformation.createUser
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.delete-icon {
  cursor: pointer;
}

/deep/ .el-table {
  max-height: 445px;
  overflow: auto;
}
</style>
